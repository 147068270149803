<template>
    <div class="login-wrap">
        <div class="header">
            <div class="logo">
                <a href="/">
                    <img src="/img/epslogonew.png" alt="">
                </a>
            </div>
            <div class="top-tips"></div>
        </div>

        <div class="main-wrap">
            <div class="main">
                <eps-login v-if="type==='eps'" />
                <changjiang-login v-if="type==='changjiang'" />
                <laoqu-login v-if="type==='laoqu'" />
                <weiguan-login v-if="type==='weiguan'" />
                <kdd-login v-if="type==='kdd'" />
                <zhitu-login v-if="type==='zhitu'" />
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import EpsLogin from './Logins/EpsLogin.vue';
import ChangjiangLogin from './Logins/ChangjiangLogin.vue';
import LaoquLogin from './Logins/LaoquLogin.vue';
import WeiguanLogin from './Logins/WeiguanLogin.vue';
import KddLogin from './Logins/KddLogin.vue';
import ZhituLogin from './Logins/ZhituLogin.vue';
import Footer from './commons/Footer.vue';
export default {
    name:'Login',
    components:{EpsLogin,ChangjiangLogin,LaoquLogin,WeiguanLogin,KddLogin,ZhituLogin,Footer},
    computed:{
        type() {
            let type = this.$route.query.type||'eps';
            return type;
        }
    }
}
</script>
<style lang="scss">
@import '../scss/login.scss';
</style>