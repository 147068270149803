<template>
    <div class="main-inner">
        <div class="login-desc">
            <div class="title">长江经济带大数据平台——为研究国家战略提供数据支撑</div>
            <p>长江经济带大数据平台集成长江经济带相关的多类别数据资源，并建立独创性的城市指数评价系统，是进行长江经济带区域研究的数据支撑平台</p>
            <ul>
                <li>定期发布长江经济带110个城市的发展评价指数，并生成城市发展简报</li>
                <li>提供长江经济带1k+个县，15k+个乡镇的2472k+条时间序列数据</li>
                <li>多样的数据可视化展示&丰富的数据分析与统计工具</li>
            </ul>

            <div class="features">
                <div class="featureItem" style="width:219px">
                    <div class="pic pic4"></div>
                    <div class="name">指数中心</div>
                    <div class="description">城市包容性发展指数<br>绿色发展指数及高质量发展指数</div>
                </div>
                <div class="featureItem">
                    <div class="pic pic5"></div>
                    <div class="name">数据中心</div>
                    <div class="description">统一检索与多维筛选<br>包含长江经济带24类主题数据</div>
                </div>
                <div class="featureItem">
                    <div class="pic pic6"></div>
                    <div class="name">统计分析</div>
                    <div class="description">强大的分析预测模型库<br>包含40余种常见分析模型</div>
                </div>
            </div>
        </div>

        <div class="split-line"></div>       

        <div class="window authenticate-window">
            <div class="window-name">
                <i class="ico2 ico-name"></i>用户登录
            </div>
            <div class="authenticate-tips" style="border:none;background:none">

            </div>
            <div class="clear"></div>
            <div class="form">
                <form @submit="accountLogin">
                    <div class="input-wrap">
                        <div class="label-name">用户名</div>
                        <input type="text" class="text" v-model="orgName" autocomplete="off">
                        <div class="clear"></div>
                    </div>

                    <div class="input-wrap">
                        <div class="label-name">密码</div>
                        <input type="password" class="text" v-model="password" autocomplete="off">
                        <div class="clear"></div>
                    </div>


                    <div class="error-tips"></div>
                    <button class="login-btn" type="login">登录</button>
                </form>
                <div class="other-btns">
                    <div class="ip" style="float:left;line-height: 40px;color:#333">当前IP：<span id="currentIp">{{ip}}</span></div>
                    <a style="float:right;line-height: 40px;" target="_blank" href="http://www.epsnet.com.cn/#/Apply?source=10">申请试用</a>
                </div>
            </div>
        </div>
        <div class="clear"></div>
    </div>
</template>


<script>
import config from '../../until/configs';
export default {
    name:'EpsLogin',
    data() {
        return {
            orgName:'',
            password:'',
            getDisabled:false,
            hasSend:false,
            ip:''
        }
    },

    methods:{
        

        
        accountLogin(e) {
            e.preventDefault();
            if(this.orgName==='') {
                this.$Message.warning('请输入账号');
                return false;
            }
            if(this.password==='') {
                this.$Message.warning('请输入密码');
                return false;
            }
            let params = {
                passName: this.orgName,
                password: this.password
            }
            this.$http.post('changjiang_login',params,true).then(res=>{
                if(res&&res.success) {
                    window.location.href = config.baseUrl[5];
                }
            });
        }
    },

    mounted() {
        


        this.$http.get('getIp').then(res=>{
            if(res&&res.ip) {
                this.ip = res.ip;
            }
        });
    }
}
</script>