<template>
    <div class="main-inner">
        <div class="login-desc">
            <div class="title">中国微观经济数据查询系统——更高效的数据获取方式</div>
            <p>中国微观经济数据查询系统是一款以企业层面微观数据为基础，集数据查询、数据匹配、数据可视化于一体的微观数据查询系统。</p>
            <ul>
                <li>对数据进行了大规模的专业化清洗与处理，最大程度的满足科研需求</li>
                <li>基于权威匹配算法，打通不同专题数据壁垒，实现数据跨库匹配查询</li>
                <li>分主题多维度数据可视化大屏呈现，全面揭示行业整体运行情况</li>
            </ul>

            <div class="features">
                <div class="featureItem">
                    <div class="pic pic10"></div>
                    <div class="name">数据资源</div>
                    <div class="description">工业企业&海关企业&创新企业<br>等多类微观数据</div>
                </div>
                <div class="featureItem">
                    <div class="pic pic11"></div>
                    <div class="name">数据查询</div>
                    <div class="description">支持单一年份数据查询<br>连续多年份时间序列查询 </div>
                </div>
                <div class="featureItem" style="width:219px">
                    <div class="pic pic12"></div>
                    <div class="name">数据匹配</div>
                    <div class="description">实现跨库企业匹配<br>为跨领域实证研究提供数据支持</div>
                </div>
            </div>
        </div>

        <div class="split-line"></div>


        <div class="window login-window" ref="invite">
            <div class="window-name">
                <i class="ico2 ico-name"></i>验证码登录
            </div>

            
            <div class="authenticate-tips" style="border:none;background:none;">
                
            </div>
            <div class="clear"></div>
            <div class="form">
                <form @submit="login">
                    <div class="input-wrap">
                        <div class="label-name">手机号/邮箱</div>
                        <input type="text" v-model="phone" class="text" autocomplete="off" placeholder="请输入您的手机号/邮箱">
                        <div class="clear"></div>
                    </div>

                    <div class="input-wrap drag-verify" v-show="!hasSend">
                        <div class="label-name">验证</div>
                        <div class="bar">
                            <div class="bar-con">请按住滑块拖到最右边</div>
                            <div class="draged" ref="draged"></div>
                            <div class="drag-btn" ref="dragBtn">
                                &gt;&gt;
                            </div>
                            <div class="cover"></div>
                        </div>
                        <div class="clear"></div>
                    </div>

                    <div class="input-wrap verify-input-area" v-show="hasSend">
                        <div class="label-name">验证码</div>
                        <input type="text" v-model="verifyCode" class="input-verifycode">
                        <button type="button" class="get-verify-btn" ref="getCodeBtn">获取验证码</button>
                        <div class="clear"></div>
                    </div>


                    <div class="error-tips"></div>
                    <button class="login-btn short f-r" type="button" @click="getIpGo">IP登录</button>
                    <button class="login-btn short" type="submit">验证码登录</button>
                </form>
                <div class="other-btns">
                    <a style="float:right;line-height: 40px;" target="_blank" href="http://www.epsnet.com.cn/#/Apply?source=10">申请试用</a>
                </div>
            </div>
        </div>

        
        <div class="clear"></div>
    </div>
</template>


<script>
import axios from 'axios';
import { checkMobile,setCookie,urlGet} from '../../until/common';
import config from '../../until/configs';
var setTimer;
export default {
    name:'EpsLogin',
    data() {
        return {
            verifyCode:'',
            phone:'',
            getDisabled:false,
            hasSend:false,
            ip:''
        }
    },

    methods:{
        getVerifyCode(){
            if(this.phone==='') {
                this.$Message.warning('请输入手机号');
                return;
            }
            if(!checkMobile(this.phone)) { 
                this.$Message.warning('请输入正确的手机号');               
                return;
            }
            this.$http.get('getSign',{account:this.phone}).then(res=>{
                if(res&&res.success) {
                    this.$refs.draged.textContent='验证通过';
                    var lastSeconds = 59;
                    var getCodeBtn = this.$refs.getCodeBtn;
                    getCodeBtn.textContent = lastSeconds+'s';
                    this.getDisabled = true;
                    setTimer=setInterval(()=>{
                        lastSeconds-=1;
                        getCodeBtn.textContent = lastSeconds+'s';
                        if(lastSeconds<1) {
                            getCodeBtn.textContent = '获取验证码';
                            clearInterval(setTimer);
                            this.getDisabled = false;
                        }
                    },1000)
                    this.$http.get('wg_getVerifyCode',{account:this.phone,sign:res.sign}).then(res=>{
                        if(res&&res.success) {
                            this.hasSend = true;
                            if(res.verifyCode) {
                                this.verifyCode = res.verifyCode;
                            }
                            this.$Message.success(res.message);
                        } else {
                            this.$Message.warning(res.message);
                        }
                    });
                } else {
                    this.$Message.warning(res.message);
                }
            });
        },

        login(e) {
            e.preventDefault();
            if(this.phone==='') {
                this.$Message.warning('请输入手机号');
                return false;
            }
            if(!checkMobile(this.phone)) { 
                this.$Message.warning('请输入正确的手机号');               
                return false;
            }
            if(this.verifyCode==='') {
                this.$Message.warning('请输入验证码');
                return false;
            }
            let params = {
                loginType:'mobile',
                mobile:this.phone,
                code:this.verifyCode,
            }
            this.$http.post('wg_login',params,true).then(res=>{
                if(res.m_sid) {
                    setCookie('wg_sid',res.m_sid);
                    this.locationTargetPage(res.m_sid);
                }
            });
        },


        getAutoLogin() {
            var that = this;
            var local = 'http://127.0.0.1:8088';
            axios.post(local+'/getLocalMacAddr',{}).then((response)=> {
                if(response.data.macAddr!='' && response.data.macAddr!=null){
                    this.$http.post('wg_loginByMacAddr',{
                        macAddr:response.data.macAddr,
                    }).then((res)=> {
                        if(res != null){
                            if(res.success) {
                                var usid = res.m_sid;
                                setCookie('wg_sid',usid);
                                that.locationTargetPage(usid);
                            }
                        }
                        return res.success;
                    }).then((resp)=> {
                        if(resp) {
                            that.locationTargetPage();
                        }
                    });
                }
            });    		
        },

        getIpGo() {
            var that = this;
            return this.$http.post('wg_ip_login',{m_sid:null},true).then((res)=> {
                if(res.success) {                    
                    var usid = res.data.m_sid;
                    setCookie('wg_sid',usid);
                    that.locationTargetPage(usid);
                } else {
                    that.$Message.error(res.message);
                    return;
                }
                return res.data.success;            
            });
        },
        locationTargetPage(sid) {
            let url = config.baseUrl[3]+'login.html?m_sid='+sid;
            if(urlGet('page')!=null){
                location.href = url+'&page='+urlGet('page');
            }else{
                location.href = url;
            }
        }
    },

    mounted() {
        this.getAutoLogin();
        var dragBtn = this.$refs.dragBtn;
        var leftBorder = this.$refs.invite.offsetLeft;
        dragBtn.onmousedown=()=>{
            dragBtn.style.transition='';
            this.$refs.draged.style.transition='';
            if(this.phone==='') {                
                return;
            }
            if(!checkMobile(this.phone)) {                
                return;
            }
            var newLeft;
            document.onmousemove=(e)=>{
                newLeft = e.pageX-leftBorder-132;
                if(newLeft<0) {
                    newLeft=0;
                }
                if(newLeft>280) {
                    newLeft=280;
                }
                dragBtn.style.left=newLeft+'px';
                this.$refs.draged.style.width=newLeft+'px';
            }
            document.onmouseup=()=>{
                document.onmousemove=null;
                document.onmouseup=null;
                if(newLeft<280) {
                    dragBtn.style.left=0;
                    dragBtn.style.transition='all 400ms ease 0s';
                    this.$refs.draged.style.width=0;
                    this.$refs.draged.style.transition='all 400ms ease 0s';
                } else {
                    this.getVerifyCode();
                    clearInterval(setTimer);
                }
            }
            return false;
        }


        this.$http.get('getIp').then(res=>{
            if(res&&res.ip) {
                this.ip = res.ip;
            }
        });
    }
}
</script>