<template>
    <div class="main-inner">
        <div class="login-desc">
            <div class="title">中国革命老区大数据平台——为研究国家战略提供数据支撑</div>
            <p>中国革命老区大数据平台集成多种数据资源，覆盖中国1500余个县级革命老区，并建立老区发展监测平台和指数平台，是一个革命老区主题研究的大数据平台。</p>
            <ul>
                <li>发布市级革命老区发展评价指数，同步监测指数指标</li>
                <li>包含5个跨省重点革命老区，八个连片革命老区和中央苏区的数据</li>
                <li>发布老区要闻，老区概况，精准扶贫等五个专题模块内容</li>
            </ul>

            <div class="features">
                <div class="featureItem" style="padding-top:7px;">
                    <div class="pic pic7"></div>
                    <div class="name">数据监测</div>
                    <div class="description">对革命老区的重要<br>经济指标进行观测和预警</div>
                </div>
                <div class="featureItem">
                    <div class="pic pic8"></div>
                    <div class="name">指数发布</div>
                    <div class="description">发布革命老区的5个发展指数<br>10个关联指数</div>
                </div>
                <div class="featureItem">
                    <div class="pic pic9"></div>
                    <div class="name">空间展示</div>
                    <div class="description">多种可视化方式展示指标数据<br>与地图结合进行空间切换</div>
                </div>
            </div>
        </div>

        <div class="split-line"></div>       

        <div class="window authenticate-window">
            <div class="window-name">
                <i class="ico2 ico-name"></i>用户登录
            </div>
            <div class="authenticate-tips" style="border:none;background:none">

            </div>
            <div class="clear"></div>
            <div class="form">
                <form @submit="accountLogin">
                    <div class="input-wrap">
                        <div class="label-name">用户名</div>
                        <input type="text" class="text" v-model="orgName" autocomplete="off">
                        <div class="clear"></div>
                    </div>

                    <div class="input-wrap">
                        <div class="label-name">密码</div>
                        <input type="password" class="text" v-model="password" autocomplete="off">
                        <div class="clear"></div>
                    </div>


                    <div class="error-tips"></div>
                    <button class="login-btn" type="login">登录</button>
                </form>
                <div class="other-btns">
                    <div class="ip" style="float:left;line-height: 40px;color:#333">当前IP：<span id="currentIp">{{ip}}</span></div>
                    <a style="float:right;line-height: 40px;" target="_blank" href="http://www.epsnet.com.cn/#/Apply?source=10">申请试用</a>
                </div>
            </div>
        </div>
        <div class="clear"></div>
    </div>
</template>


<script>
import config from '../../until/configs';
export default {
    name:'EpsLogin',
    data() {
        return {
            orgName:'',
            password:'',
            getDisabled:false,
            hasSend:false,
            ip:''
        }
    },

    methods:{
        accountLogin(e) {
            e.preventDefault();
            if(this.orgName==='') {
                this.$Message.warning('请输入账号');
                return false;
            }
            if(this.password==='') {
                this.$Message.warning('请输入密码');
                return false;
            }
            let params = {
                loginName: this.orgName,
                loginType: 'pwd',
                password: this.password
            }
            this.$http.post('laoqu_login',params,true).then(res=>{
                if(res&&res.success) {
                    window.location.href=config.baseUrl[6];
                } else {
                    this.$Message.error(res.message);
                }
            });
        }
    },

    mounted() {
        


        this.$http.get('getIp').then(res=>{
            if(res&&res.ip) {
                this.ip = res.ip;
            }
        });
    }
}
</script>