<template>
    <div class="main-inner">
        <div class="login-desc">
            <div class="title">EPS数据平台——创造数据服务新模式!</div>
            <p>EPS（Easy Professional Superior）数据平台是集丰富的数值型数据资源和强大的分析预测<br>系统为一体的覆盖多学科、面向多领域的综合性统计数据与分析平台。</p>
            <ul>
                <li>包含<span id="cudenum">89</span>个数据库，<span id="childcudenum">388</span>个子库，涉及<span id="fieldnum">40</span>+领域，<span id="subjectnum">30</span>+一级学科</li>
                <li>数据总量超<span id="totalnum">50</span>亿条，每年新增近2亿条，约80%数据全网首发更新</li>
                <li>10+数据可视化图表&amp;数字地图，25+数据处理与分析工具</li>
            </ul>

            <div class="features">
                <div class="featureItem">
                    <div class="pic pic1"></div>
                    <div class="name">数据全面</div>
                    <div class="description">拥有十大数据库集群<br>数据覆盖全球&amp;国内全部县市</div>
                </div>
                <div class="featureItem">
                    <div class="pic pic2"></div>
                    <div class="name">检索高效</div>
                    <div class="description">指标多维快捷查询<br>跨库&amp;库内两种检索方式 </div>
                </div>
                <div class="featureItem">
                    <div class="pic pic3"></div>
                    <div class="name">工具专业</div>
                    <div class="description">强大的经济计量系统<br>支持自有数据云端上传分析</div>
                </div>
            </div>
        </div>

        <div class="split-line"></div>


        <div class="window login-window" v-show="type===1" ref="invite">
            <div class="window-name">
                <i class="ico2 ico-name"></i>手机验证登录
            </div>
            <div class="change-btn-wrap">
                <div class="change-btn" @click="type=2">
                    <i class="ico2 ico-change"></i>
                </div>						
            </div>
            
            <div class="authenticate-tips">
                机构账号登录<i class="ico"></i><i class="ico2"></i>
            </div>
            <div class="clear"></div>
            <div class="form">
                <form @submit="login">
                    <div class="input-wrap">
                        <div class="label-name">手机号</div>
                        <div class="phone-suffix">中国大陆 +86</div>
                        <input type="text" v-model="phone" class="input-phone" autocomplete="off" placeholder="请输入您的手机号">
                        <div class="clear"></div>
                    </div>

                    <div class="input-wrap drag-verify" v-show="!hasSend">
                        <div class="label-name">验证</div>
                        <div class="bar">
                            <div class="bar-con">请按住滑块拖到最右边</div>
                            <div class="draged" ref="draged"></div>
                            <div class="drag-btn" ref="dragBtn">
                                &gt;&gt;
                            </div>
                            <div class="cover"></div>
                        </div>
                        <div class="clear"></div>
                    </div>

                    <div class="input-wrap verify-input-area" v-show="hasSend">
                        <div class="label-name">验证码</div>
                        <input type="text" v-model="verifyCode" class="input-verifycode">
                        <button type="button" class="get-verify-btn" ref="getCodeBtn">获取验证码</button>
                        <div class="clear"></div>
                    </div>


                    <div class="error-tips"></div>
                    <button class="login-btn" type="submit">登录</button>
                </form>
                <div class="other-btns">
                    <a style="float:right;line-height: 40px;" target="_blank" href="http://www.epsnet.com.cn/#/Apply?source=10">申请试用</a>
                </div>
            </div>
        </div>

        <div class="window authenticate-window" v-show="type===2">
            <div class="window-name" title="实名认证后，您可获得机构外访问权限以及升级数据平台权限">
                <i class="ico2 ico-name"></i>机构账号登录
            </div>
            <div class="change-btn-wrap">
                <div class="change-btn" @click="type=1">
                    <i class="ico ico-change"></i>
                </div>						
            </div>
            <div class="authenticate-tips">
                手机验证登录<i class="ico"></i>
                <i class="ico2"></i>
            </div>
            <div class="clear"></div>
            <div class="form">
                <form @submit="accountLogin">
                    <div class="input-wrap">
                        <div class="label-name">机构账号</div>
                        <input type="text" class="text" v-model="orgName" autocomplete="off">
                        <div class="clear"></div>
                    </div>

                    <div class="input-wrap">
                        <div class="label-name">密码</div>
                        <input type="password" class="text" v-model="password" autocomplete="off">
                        <div class="clear"></div>
                    </div>


                    <div class="error-tips"></div>
                    <button class="login-btn" type="login">登录</button>
                </form>
                <div class="other-btns">
                    <div class="ip" style="float:left;line-height: 40px;color:#333">当前IP：<span id="currentIp">{{ip}}</span></div>
                    <a style="float:right;line-height: 40px;" target="_blank" href="http://www.epsnet.com.cn/#/Apply?source=10">申请试用</a>
                </div>
            </div>
        </div>
        <div class="clear"></div>
    </div>
</template>


<script>
import { checkMobile, setCookie} from '../../until/common';
import config from '../../until/configs';
var setTimer;
export default {
    name:'EpsLogin',
    data() {
        return {
            type:1,
            verifyCode:'',
            phone:'',
            orgName:'',
            password:'',
            getDisabled:false,
            hasSend:false,
            ip:''
        }
    },

    methods:{
        getVerifyCode(){
            if(this.phone==='') {
                this.$Message.warning('请输入手机号');
                return;
            }
            if(!checkMobile(this.phone)) { 
                this.$Message.warning('请输入正确的手机号');               
                return;
            }
            this.$http.get('getSign',{account:this.phone}).then(res=>{
                if(res&&res.success) {
                    this.$refs.draged.textContent='验证通过';
                    var lastSeconds = 59;
                    var getCodeBtn = this.$refs.getCodeBtn;
                    getCodeBtn.textContent = lastSeconds+'s';
                    this.getDisabled = true;
                    setTimer=setInterval(()=>{
                        lastSeconds-=1;
                        getCodeBtn.textContent = lastSeconds+'s';
                        if(lastSeconds<1) {
                            getCodeBtn.textContent = '获取验证码';
                            clearInterval(setTimer);
                            this.getDisabled = false;
                        }
                    },1000)
                    this.$http.get('accountGetVerifyCode',{account:this.phone,sign:res.sign}).then(res=>{
                        if(res&&res.success) {
                            this.hasSend = true;
                            if(res.verifyCode) {
                                this.verifyCode = res.verifyCode;
                            }
                            this.$Message.success(res.message);
                        } else {
                            this.$Message.warning(res.message);
                        }
                    });
                } else {
                    this.$Message.warning(res.message);
                }
            });
        },

        login(e) {
            e.preventDefault();
            if(this.phone==='') {
                this.$Message.warning('请输入手机号');
                return false;
            }
            if(!checkMobile(this.phone)) { 
                this.$Message.warning('请输入正确的手机号');               
                return false;
            }
            if(this.verifyCode==='') {
                this.$Message.warning('请输入验证码');
                return false;
            }
            let params = {
                loginType:'mobile',
                mobile:this.phone,
                code:this.verifyCode,
            }
            this.$http.post('epsLogin',params,true).then(res=>{
                if(res&&res.sid) {
                    setCookie('eps_sid',res.sid);
                    var key = this.$route.query.key;
                    var cubeId = this.$route.query.cubeId;                    
                    var locationUrl;
					if(res.success) {
                        locationUrl = config.baseUrl[1]+'auth/platform.html?sid='+res.sid;
						if(key) {
							locationUrl+='&key='+key;
						}
						if(cubeId) {
							locationUrl+='&cubeId='+cubeId;
						}
						location.href=locationUrl;			
					}                    
                }
            });
        },
        accountLogin(e) {
            e.preventDefault();
            if(this.orgName==='') {
                this.$Message.warning('请输入账号');
                return false;
            }
            if(this.password==='') {
                this.$Message.warning('请输入密码');
                return false;
            }
            let params = {
                loginName: this.orgName,
                loginType: 'pwd',
                password: this.password
            }
            this.$http.post('epsLogin',params,true);
        }
    },

    mounted() {
        var dragBtn = this.$refs.dragBtn;
        var leftBorder = this.$refs.invite.offsetLeft;
        dragBtn.onmousedown=()=>{
            dragBtn.style.transition='';
            this.$refs.draged.style.transition='';
            if(this.phone==='') {                
                return;
            }
            if(!checkMobile(this.phone)) {                
                return;
            }
            var newLeft;
            document.onmousemove=(e)=>{
                newLeft = e.pageX-leftBorder-132;
                if(newLeft<0) {
                    newLeft=0;
                }
                if(newLeft>280) {
                    newLeft=280;
                }
                dragBtn.style.left=newLeft+'px';
                this.$refs.draged.style.width=newLeft+'px';
            }
            document.onmouseup=()=>{
                document.onmousemove=null;
                document.onmouseup=null;
                if(newLeft<280) {
                    dragBtn.style.left=0;
                    dragBtn.style.transition='all 400ms ease 0s';
                    this.$refs.draged.style.width=0;
                    this.$refs.draged.style.transition='all 400ms ease 0s';
                } else {
                    this.getVerifyCode();
                    clearInterval(setTimer);
                }
            }
            return false;
        }


        this.$http.get('getIp').then(res=>{
            if(res&&res.ip) {
                this.ip = res.ip;
            }
        });
    }
}
</script>