<template>
    <div class="main-inner">
        <div class="login-desc">
            <div class="title">EPS知识服务平台——发现数据的价值</div>
            <p>EPS知识服务平台是一款旨在满足学者在实证研究过程中“从查阅文献到收集数据及从利用数据到得出结论”环节中的数据发现与数据分析需求所研发的一款数据资源类知识服务平台。</p>
            <ul>
                <li>拥有100万+张统计表格，700万+条时间序列，近百万篇经济类核刊论文元数据</li>
                <li>基于经济类实证研究论文提取2w+研究主题及研究方法，8w+论文引用统计指标</li>
                <li>通过知识图谱描述知识资源的相关关系，显示知识发展进程与核心结构</li>
            </ul>
            <div class="features">
                <div class="featureItem">
                    <div class="pic pic13"></div>
                    <div class="name">主题发现</div>
                    <div class="description">庞大的主题词库<br>覆盖经济核刊全部研究主题</div>
                </div>
                <div class="featureItem">
                    <div class="pic pic14"></div>
                    <div class="name">文献计量</div>
                    <div class="description">基于共线关联统计<br>多维度的文献计量分析服务</div>
                </div>
                <div class="featureItem">
                    <div class="pic pic15"></div>
                    <div class="name">智能报告</div>
                    <div class="description">智能分析统计表格<br>一键生成图文并茂分析报告</div>
                </div>
            </div>
        </div>

        <div class="split-line"></div>       

        <div class="window authenticate-window kdd">
            <div class="window-name">
                <i class="ico2 ico-name"></i>用户登录
            </div>
            <div class="authenticate-tips" style="border:none;background:none">

            </div>
            <div class="clear"></div>
            <div class="form">
                <form @submit="accountLogin">
                    <div class="input-wrap">
                        <div class="label-name">用户名</div>
                        <input type="text" class="text" v-model="orgName" autocomplete="off">
                        <div class="clear"></div>
                    </div>

                    <div class="input-wrap">
                        <div class="label-name">密码</div>
                        <input type="password" class="text" v-model="password" autocomplete="off">
                        <div class="clear"></div>
                    </div>

                    <div class="input-wrap">
                        <div class="label-name">验证码</div>
                        <input type="password" class="input-verifycode" v-model="verifyCode" autocomplete="off">
                        <div class="code-wrap">
                            <img class="code" :src="baseUrl+'code.do?random='+random" alt="" @click="random=Math.random()" />
                        </div>
                        <div class="clear"></div>
                    </div>
                    <button class="login-btn short f-r" type="button" @click="ipLogin">IP登录</button>
                    <button class="login-btn short" type="login">登录</button>
                </form>
                <div class="other-btns">
                    <div class="ip" style="float:left;line-height: 40px;color:#333">当前IP：<span id="currentIp">{{ip}}</span></div>
                    <a style="float:right;line-height: 40px;" target="_blank" href="http://www.epsnet.com.cn/#/Apply?source=40">申请试用</a>
                </div>
            </div>
        </div>
        <div class="clear"></div>
    </div>
</template>


<script>
import config from '../../until/configs';
export default {
    name:'EpsLogin',
    data() {
        return {
            orgName:'',
            password:'',
            getDisabled:false,
            hasSend:false,
            ip:'',
            baseUrl:config.baseUrl[4],
            random:0,
            verifyCode:''
        }
    },

    methods:{
        accountLogin(e) {
            e.preventDefault();
            if(this.orgName==='') {
                this.$Message.warning('请输入账号');
                return false;
            }
            if(this.password==='') {
                this.$Message.warning('请输入密码');
                return false;
            }
            if(this.verifyCode==='') {
                this.$Message.warning('请输入验证码');
                return false;
            }
            let params = {
                userName: this.orgName,
                type: 'pwd',
                password: this.password,
				verifyCode: this.verifyCode,
            }
            this.$http.post('kdd_login',params,true).then(res=>{
                if (res.loginState === "0") {
                    this.$Message.error(res.message);
                } else {
                    window.location.href = this.baseUrl;
                }
            });
        },
        ipLogin() {
            this.$http.post('kdd_login',{type:'ip'},true).then(res=>{
                if(res.loginState === '1') {
                    window.location.href=this.baseUrl;
                } else {
                    this.$Message.error(res.message);
                }
            });
        }
    },

    mounted() {
        this.$http.get('getIp').then(res=>{
            if(res&&res.ip) {
                this.ip = res.ip;
            }
        });
    }
}
</script>