<template>
    <div class="main-inner">
        <div class="login-desc">
            <div class="title">知图平台一图情人自己的平台!!</div>
            <p>知图平台是一款面向图情人以内容生产与知识服务为核心，以提升图情研究者学术水平与图情从业者职业技能为目标，以提升图书馆管理能力与服务能力为使命的业务分享与研究支撑平台。</p>
            <ul>
                <li>拥有国内图情领域顶尖专家咨询团队，具有更高的专业性、学术性与权威性</li>
                <li>知图致力于提升图书馆员自身的研究能力、业务能力和服务能力</li>
                <li>构建专家、主编、作者、读者的互动交流机制，图情人研究与业务交流的首选平台</li>
            </ul>

            <div class="features">
                <div class="featureItem">
                    <div class="pic pic1"></div>
                    <div class="name">深度挖掘</div>
                    <div class="description">知识可视化呈现<br>实现知识发现与知识推荐</div>
                </div>
                <div class="featureItem">
                    <div class="pic pic3"></div>
                    <div class="name">场景关联</div>
                    <div class="description">十余种应用场景<br>覆盖图情研究与业务方向</div>
                </div>
                <div class="featureItem">
                    <div class="pic pic16"></div>
                    <div class="name">权威实用</div>
                    <div class="description">图情权威专家入驻<br>充分解决学界与业界关切</div>
                </div>
            </div>
        </div>

        <div class="split-line"></div>


        <div class="window login-window" ref="invite">
            <div class="window-name">
                <i class="ico2 ico-name"></i>手机验证登录
            </div>            
            <div class="authenticate-tips" style="border:none;background:none;"></div>
            <div class="clear"></div>
            <div class="form">
                <form @submit="login">
                    <div class="input-wrap">
                        <div class="label-name">手机号</div>
                        <div class="phone-suffix">中国大陆 +86</div>
                        <input type="text" v-model="phone" class="input-phone" autocomplete="off" placeholder="请输入您的手机号">
                        <div class="clear"></div>
                    </div>

                    <div class="input-wrap drag-verify" v-show="!hasSend">
                        <div class="label-name">验证</div>
                        <div class="bar">
                            <div class="bar-con">请按住滑块拖到最右边</div>
                            <div class="draged" ref="draged"></div>
                            <div class="drag-btn" ref="dragBtn">
                                &gt;&gt;
                            </div>
                            <div class="cover"></div>
                        </div>
                        <div class="clear"></div>
                    </div>

                    <div class="input-wrap verify-input-area" v-show="hasSend">
                        <div class="label-name">验证码</div>
                        <input type="text" v-model="verifyCode" class="input-verifycode">
                        <button type="button" class="get-verify-btn" ref="getCodeBtn">获取验证码</button>
                        <div class="clear"></div>
                    </div>


                    <div class="error-tips"></div>
                    <button class="login-btn" type="submit">登录</button>
                </form>
                <div class="other-btns">
                    <a style="float:right;line-height: 40px;" target="_blank" href="http://www.epsnet.com.cn/#/Apply?source=50">申请试用</a>
                </div>
            </div>
        </div>

        
        <div class="clear"></div>
    </div>
</template>


<script>
import { checkMobile, setCookie} from '../../until/common';
import config from '../../until/configs';
var setTimer;
export default {
    name:'EpsLogin',
    data() {
        return {
            verifyCode:'',
            phone:'',
            getDisabled:false,
            hasSend:false,
            ip:''
        }
    },

    methods:{
        getVerifyCode(){
            if(this.phone==='') {
                this.$Message.warning('请输入手机号');
                return;
            }
            if(!checkMobile(this.phone)) { 
                this.$Message.warning('请输入正确的手机号');               
                return;
            }
            this.$http.get('zhitu_getSign',{account:this.phone}).then(res=>{
                if(res&&res.success) {
                    this.$refs.draged.textContent='验证通过';
                    var lastSeconds = 59;
                    var getCodeBtn = this.$refs.getCodeBtn;
                    getCodeBtn.textContent = lastSeconds+'s';
                    this.getDisabled = true;
                    setTimer=setInterval(()=>{
                        lastSeconds-=1;
                        getCodeBtn.textContent = lastSeconds+'s';
                        if(lastSeconds<1) {
                            getCodeBtn.textContent = '获取验证码';
                            clearInterval(setTimer);
                            this.getDisabled = false;
                        }
                    },1000)
                    this.$http.get('zhitu_accountGetVerifyCode',{account:this.phone,sign:res.sign}).then(res=>{
                        if(res&&res.success) {
                            this.hasSend = true;
                            if(res.verifyCode) {
                                this.verifyCode = res.verifyCode;
                            }
                            this.$Message.success(res.message);
                        } else {
                            this.$Message.warning(res.message);
                        }
                    });
                } else {
                    this.$Message.warning(res.message);
                }
            });
        },

        login(e) {
            e.preventDefault();
            if(this.phone==='') {
                this.$Message.warning('请输入手机号');
                return false;
            }
            if(!checkMobile(this.phone)) { 
                this.$Message.warning('请输入正确的手机号');               
                return false;
            }
            if(this.verifyCode==='') {
                this.$Message.warning('请输入验证码');
                return false;
            }
            let params = {
                account:this.phone,
                verifyCode:this.verifyCode,
            }
            this.$http.post('zhitu_login',params,true).then(res=>{
                if(res.sid) {
                    setCookie('zhitu_sid',res.sid);
                    let locationUrl = this.$route.query.locationUrl;
                    location.href = config.baseUrl[2]+'account.html#/login/'+res.sid+'/'+locationUrl;
                }
            });
        },
    },

    mounted() {
        var dragBtn = this.$refs.dragBtn;
        var leftBorder = this.$refs.invite.offsetLeft;
        dragBtn.onmousedown=()=>{
            dragBtn.style.transition='';
            this.$refs.draged.style.transition='';
            if(this.phone==='') {                
                return;
            }
            if(!checkMobile(this.phone)) {                
                return;
            }
            var newLeft;
            document.onmousemove=(e)=>{
                newLeft = e.pageX-leftBorder-132;
                if(newLeft<0) {
                    newLeft=0;
                }
                if(newLeft>280) {
                    newLeft=280;
                }
                dragBtn.style.left=newLeft+'px';
                this.$refs.draged.style.width=newLeft+'px';
            }
            document.onmouseup=()=>{
                document.onmousemove=null;
                document.onmouseup=null;
                if(newLeft<280) {
                    dragBtn.style.left=0;
                    dragBtn.style.transition='all 400ms ease 0s';
                    this.$refs.draged.style.width=0;
                    this.$refs.draged.style.transition='all 400ms ease 0s';
                } else {
                    this.getVerifyCode();
                    clearInterval(setTimer);
                }
            }
            return false;
        }


        this.$http.get('getIp').then(res=>{
            if(res&&res.ip) {
                this.ip = res.ip;
            }
        });
    }
}
</script>